<template>
<nav class="navbar navbar-expand-md sticky-top navbar-light" style="background-color: #f4f6f8;">
<a class="navbar-brand" to="{ path: '/' }" style="color: rgb(155, 46, 46);">
                        <strong>Picture tools</strong>
                    </a>
                   <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".dual-collapse2">
            <span class="navbar-toggler-icon"></span>
        </button>

    <div class="w-100 order-1 order-md-0">
         <span  v-if="runningEnv==='dev'" style="color:black">Developer environment</span>
    </div>

    <div class="navbar-collapse collapse w-100 order-3 dual-collapse2">
        <ul class="navbar-nav ml-auto">
           <!-- <li class="nav-item">
         <router-link style="color: rgb(155, 46, 46);"  class="nav-link" to="/">Camera API</router-link>
      </li> -->
      <!-- <li class="nav-item">
          <router-link style="color: rgb(155, 46, 46);" class="nav-link" to="/phone">Customer phone API</router-link>
      </li> -->
          <!-- <li class="nav-item">
          <router-link style="color: rgb(155, 46, 46);" class="nav-link" to="/smartphone">Timer Feature API</router-link>
      </li> -->
        </ul>
    </div>
</nav>
</template>

<script>
import axios from "axios";
export default {
  name: 'NavComponent',
  components: {
 
  },
  data: function () {
    return {
      runningEnv: "",
    };
  },
  created() {
    this.getEnv();
  },
  methods: {
    async getEnv() {
      await axios
        .get("api/getAppEnv")
        .then((res) => {
          this.runningEnv = res.data.env;
         //console.log("Res data " + JSON.stringify(res.data));
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },

}
</script>
