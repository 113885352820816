<template>
  <div id="CameraSimulator">
    <div class="container-fluid">
      <main>

        <div class="row" style="padding-top: 5vh; padding-bottom: 10px">
          <div class="col-md-6 col-12">
            <div class="accordion" id="accordionExample">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button
                      style="width: 100%"
                      class="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      EXIF reader
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseOne"
                  class="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    <p>Upload picture to read EXIF information.</p>
                    <div class="Row">
                      <input
                        class="Column"
                        type="file"
                        @change="uploadFile"
                        ref="file"
                      />
                      <button
                        class="Column btn btn-success"
                        type="button"
                        @click="submitFile"
                      >
                        Upload
                      </button>
                    </div>
                    <div
                      v-if="
                        imageMetadata && Object.keys(imageMetadata).length != 0
                      "
                    >
                      <p>EXIF</p>
                      <pre
                        style="background-color: rgb(155, 46, 46); color: white"
                        >{{ imageMetadata }}
                      </pre>
                      <br />
                      <button class="btn btn-dark" @click="downloadJson">
                        Download
                      </button>
                      <button class="btn btn-info" @click="copyToClipboard">
                        Copy to clipboard
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

             <div class="col-md-6 col-12">
          </div>
        </div>

          <div class="row" style="padding-top: 5vh; padding-bottom: 10px">
          <div class="col-md-6 col-12">
            <div class="accordion" id="accordionExample2">
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0">
                    <button
                      style="width: 100%"
                      class="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      Camera default states uploader
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseTwo"
                  class="collapse show"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample2"
                >
                  <div class="card-body">
                    <p>Upload CSV file with IMEIs to create default camera states.</p>
                    <div class="Row">
                      <input
                        class="Column"
                        type="file"
                        @change="uploadCameraImeisFile"
                        ref="fileCsv"
                      />
                      <button
                        class="Column btn btn-success"
                        type="button"
                        @click="submitCameraImeisFile"
                      >
                        Upload
                      </button>
                    </div>
                    <div
                      v-if="
                        csvResponse && Object.keys(csvResponse).length != 0
                      "
                    >
                      <p>Default camera settings will be created for the cameras with following IMEI numbers</p>
                      <pre
                        style="background-color: rgb(155, 46, 46); color: white"
                        >{{ csvResponse }}
                      </pre>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

             <div class="col-md-6 col-12">
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "CameraSimulator",
  components: {},
  props: {
    msg: String,
  },
  data: function () {
    return {
      imageMetadata: {},
      csvResponse:{},
      appVersion: "",
      runningEnv: "",
      removeErrorsMsg: false,
    };
  },
  // 	width:2560px;
  // height: 1920px ;
  created() {
    this.getEnv();
  },
  filters: {
    pretty: function (value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    },
  },
  methods: {
    getImgUrl(pic) {
      return require("../assets/" + pic);
    },
    metaDataExists() {
      console.log(
        this.imageMetadata && Object.keys(this.imageMetadata).length !== 0
      );
      return (
        this.imageMetadata &&
        Object.keys(this.imageMetadata).length !== 0 &&
        Object.getPrototypeOf(this.imageMetadata) !== Object.prototype
      );
    },
    copyToClipboard() {
      let text = JSON.stringify(this.imageMetadata, null, 2);
      console.log(text);
      var input = document.createElement("textarea");
      input.setAttribute("value", text);
      input.value = text;
      document.body.appendChild(input);
      try {
        input.select();
        input.click();
        input.focus();
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Testing code was copied " + successful + " " + msg);
      } catch (err) {
        console.log("Oops, unable to copy");
      }
      document.body.removeChild(input);
    },
    downloadJson() {
      let imageName = this.imageMetadata["imagename"].split(".")[0];
      let text = JSON.stringify(this.imageMetadata, null, 2);
      let filename = `${imageName}.json`;
      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:application/json;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    uploadFile() {
      this.Images = this.$refs.file.files[0];
    },
    async submitFile() {
      const formData = new FormData();
      formData.append("image", this.Images);
      const headers = { "Content-Type": "multipart/form-data" };
      await axios
        .post("api/image-upload/exif-reader", formData, {
          headers,
        })
        .then((res) => {
          res.data.files; // binary representation of the file
          res.status; // HTTP status
          console.log(res.status);
          console.log(res.data);
          this.imageMetadata = res.data;
        })
        .catch((error) => {
          console.log(error.message);
          this.imageMetadata = {
            error: error.message,
          };
        });
    },
       uploadCameraImeisFile() {
      this.cameraImeis = this.$refs.fileCsv.files[0];
    },
    async submitCameraImeisFile() {
      const formData = new FormData();
      formData.append("file", this.cameraImeis);
      const headers = { "Content-Type": "multipart/form-data" };
      await axios
        .post("api/csv-upload/camera-states", formData, {
          headers,
        })
        .then((res) => {
          res.data.files; // binary representation of the file
          res.status; // HTTP status
          console.log(res.status);
          console.log(res.data);
          this.csvResponse = res.data;
        })
        .catch((error) => {
          console.log(error.message);
          this.csvResponse  = {
            error: error.message,
          };
        });
    },
    async getEnv() {
      await axios
        .get("api/getAppEnv")
        .then((res) => {
          this.runningEnv = res.data.env;
          //console.log("Res data " + JSON.stringify(res.data));
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button {
  background-color: rgb(155, 46, 46);
  color: white;
}
.btn {
  text-transform: unset !important;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background-color: #f4f6f8;
}
.card-img-top {
  width: 400px;
  height: 15vw;
  object-fit: contain;
}
</style>
